import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import './TabNav.css';
import style from './TabNav.module.css'
import Translator from '../I18n/Translator';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yellow } from '@mui/material/colors';
import { LinguagemContext } from '../../common/context/LinguagemContext';

//receba por parametros theme=theme link_gripe="" link_zika= ""
const TabNav = ({theme, currentPage, link_gripe, link_zika, url_atual}) => {

    
    const { t } = useTranslation();
    const {language} = useContext(LinguagemContext);

    const rectangleCount = 10; // Número de retângulos
    const blueTones = [
        "#CCCCFF",
        "#9999FF",
        "#9999FF",
        "#6666FF",
        "#6666FF",
        "#3333FF",
        "#3333FF",
        "#0000FF",
        "#0000CC",
        "#000099"
    ];
    

    const rectangles = [];
    for (let i = 0; i < rectangleCount; i++) {
        const style = {
            backgroundColor: blueTones[i],
            maxHeight: "5px",
            minHeight: "2px",
            maxWidth: "10%",
            minWidth: "10%",
            flex: "1"
        };
        rectangles.push(<div key={i} style={style}></div>);
    }

    const pages = {
        "Painel Completo": "/mapa",
        "Metagenomica": "/metagenomic",
        "IBP": "/ibp",
        "Clima": "/climate",
        "Web Based": "/webbased"
    };

    const pages_translated = {
        "Painel Completo": "links_menu.mapa",
        "Metagenomica": "links_menu.metagenomic",
        "IBP": "links_menu.ibp",
        "Clima": "links_menu.climate",
        "Web Based": "links_menu.webbased"
    };
    const corDesativado = "rgba(12, 12, 160, 0.397)";
    const corAtivado = "#000099";
    const isResumido = currentPage === "Painel Resumido";
    /*const currentPage_ = url_atual === "/maparesumido" ? "Painel Completo" : currentPage;*/

    return (
        <div>
            <div className='nav_abas' style={{backgroundColor: theme === "dark" ? "black" : "white"}}>
                <div className='linha_sup_menu'>
                    <div className="menu" >
                        <a href={link_gripe} style={{ textDecoration: 'none' }}>
                            <button href="#" className="menu-button" style={{backgroundColor: theme === "dark" ? "#363636" : "#d6d6d6"}}><Translator path="dashboard.gripe" /></button> 
                        </a>
                    </div>   
                    <div className="menu2">
                        <a href={link_zika} style={{ textDecoration: 'none' }}>
                            <button className="menu-button2" ><Translator path="dashboard.arbo" /> {/*<img class="arrow ocultar" src="imagens/seta-para-expandir.png"/>*/}</button>
                        </a>
                    </div>
                    <div className='div_help'>
                        <a href={language === "pt" ? "Dados/help.pdf" : "Dados/help_ingles.pdf"}  target='_blank' rel='noopener noreferre'>
                            <img src="imagens/icones/help.png" className='img_help' alt="Help Icon"/>
                        </a>
                    </div>
                </div>
                <div style={{minHeight: "5px", minWidth:"100%", display:"flex"}}>{rectangles}</div> 
                <div className="menu3">
                    <Link to={isResumido ? "/mapa" : "#"} style={{ textDecoration: 'none' }}>
                        <button href={isResumido ? "/mapa" : "#"} className="submenu-button" style={{color: !isResumido ? 'white' : 'rgba(255,255,255,0.5)'}}>{isResumido ? t(pages_translated["Painel Completo"]) : t(pages_translated[currentPage])}{/*<img class="arrow" src="imagens/seta-para-expandir.png"/>*/}</button>
                    </Link>
                    {/*<div className="submenu">
                        {Object.entries(pages)
                            .filter(([name]) => (name !== (isResumido ? "Painel Completo" : currentPage)) ) // Exclui a página atual
                            .map(([name, path]) => (
                                <a href={path} key={name}>{t(pages_translated[name])}</a>
                            ))}
                        
                        
                    </div> */}  
                </div> 
            </div> 
            
            <div className='space_nav_abas_'></div> 
        </div>
    );
};

export default TabNav;