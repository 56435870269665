import { Button, Modal } from "react-bootstrap";
import { Line, Bar } from "react-chartjs-2";

import './PopupGrafico.css'

function PopupGrafico({show, onHide, options, data, type, titulo, linguagem}) {
  if(type === "Line"){
    return (
      <Modal
          show={show}
          onHide={onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {titulo}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <Line options={options} data={data}/>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide}>{linguagem === "pt" ? "Fechar" : "Close"}</Button>
          </Modal.Footer>
      </Modal>
      );
  } else {
    return (

       <Modal
          show={show}
          onHide={onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {titulo}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <Bar options={options} data={data}/>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide}>{linguagem === "pt" ? "Fechar" : "Close"}</Button>
          </Modal.Footer>
            </Modal>
        
      );
  }
}

export default PopupGrafico;