import { Link } from 'react-router-dom';
import './Footer.css'
import preval from 'preval.macro'
const Footer =  (props) => {
    
    const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`
    return (
        <footer className="page-footer font-small blue pt-4">
            <div className="footer-copyright text-center py-3">
                Atualizado em {dateTimeStamp}
            </div>

        </footer>
    )
};

export default Footer