import React from 'react';
import { Table } from 'react-bootstrap';
import style from './Tabela.module.css';
import Translator from '../I18n/Translator';

const valorNil = 'nil'; // Substitua por qualquer valor padrão desejado para casos em que o valor é nulo

const isAllSelect = (variavel) => {
    return variavel === "All" || variavel === "Todos";
}

const preparaInt = (valor) => {
    var valor_temp = valor + "";
    valor_temp = valor_temp.replace(".", "");
    valor_temp = valor_temp.replace(",", "");
    return valor_temp;
}

const trataNaNeNull = (dado) => {
    return dado === null || dado === "" || dado === "NaN" ? valorNil : dado;
}

const trataNaNeNullInt = (dado) => {
    return dado === null || dado === "" || dado === "NaN" ? valorNil : parseInt(dado);
}

const fixaCasasDecimais = (valor, casasDecimais) => {
    if(valor === valorNil) 
        return valorNil;
    return parseFloat(valor).toFixed(casasDecimais).replace(',', '.');
}

function Tabela({ indice, estadoSelecionado, t, theme, campoOrdem, setCampoOrdem, decrescente, setDecrescente, handleChangeEstadoTabela, handleChangeMunicipioTabela, tabelaOrdenada, exportarDados }) {

    return (
        <div className={exportarDados ? style.ocultar : style.table}>
            <div className={style.titulo_tabela}><h5><center>{!isAllSelect(estadoSelecionado) ? t("dashboard.table.titulo") : t("dashboard.table.titulo_estados")}</center></h5></div>
            <Table id={exportarDados ? "dtBasicExampleExport" :  "dtBasicExample"} striped bordered hover variant={theme === "dark" ? "dark" : ""}>
                <thead>
                    <tr>
                        <th><a href='#' style={{ color: campoOrdem === "estado" ? "cadetblue" : ""}} onClick={() => {
                            if (campoOrdem === "estado") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                            setCampoOrdem("estado")
                        }}>
                        <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.estado"} /></a>
                        </th>

                        <th><a href='#' style={{ color: campoOrdem === "municipio" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "municipio") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("municipio")
                            }}>
                            <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.col1"} /></a>
                        </th>

                        <th><a href='#' style={{ color: campoOrdem === "total_arbov" ? "cadetblue" : ""}} onClick={() => {
                            if (campoOrdem === "total_arbov") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                            setCampoOrdem("total_arbov")
                        }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.col7"} />  </a></th>
                        <th><a href='#' style={{ color: campoOrdem === "total_geral" ? "cadetblue" : ""}} onClick={() => {
                            if (campoOrdem === "total_geral") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                            setCampoOrdem("total_geral")
                        }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.col8"} />  </a></th>
                        <th><a href='#' style={{ color: campoOrdem === "taxaarbov_atendtotais" ? "cadetblue" : ""}} onClick={() => {
                            if (campoOrdem === "taxaarbov_atendtotais") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                            setCampoOrdem("taxaarbov_atendtotais")
                        }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.col6"} /></a></th>
                        

                        <th><a href='#' style={{ color: campoOrdem === "dqi" ? "cadetblue" : ""}} onClick={() => {
                            if (campoOrdem === "dqi") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                            setCampoOrdem("dqi")
                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.dqi"} /> </a>
                        </th>

                        <th><a href='#' style={{ color: campoOrdem === "perc_completude" ? "cadetblue" : ""}} onClick={() => {
                            if (campoOrdem === "perc_completude") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                            setCampoOrdem("perc_completude")
                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.perc_completude"} /> </a>
                        </th>

                        <th><a href='#' style={{ color: campoOrdem === "diff_2w" ? "cadetblue" : ""}} onClick={() => {
                            if (campoOrdem === "diff_2w") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                            setCampoOrdem("diff_2w")
                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.perc_tempestividade"} /> </a>
                        </th>

                        <th>
                            <a href='#' style={{ color: campoOrdem === "prob_gro_trend_arbov" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "prob_gro_trend_arbov") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("prob_gro_trend_arbov")
                            }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.probabilidade_crescimento"} /> %  </a>
                        </th>

                        {(exportarDados || indice.toLowerCase() === "evi") &&
                        
                            <th><a href='#' style={{ color: campoOrdem === "sinal_evi_consec_arbov" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "sinal_evi_consec_arbov") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("sinal_evi_consec_arbov")
                            }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_evi"} /> </a>
                            </th>
                        }

                        {(exportarDados || indice.toLowerCase() === "evi") &&
                            <th>
                                <a href='#' style={{ color: campoOrdem === "limite_superior_evi_arbov" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "limite_superior_evi_arbov") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("limite_superior_evi_arbov")
                                    }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.limite"} />   <Translator path={"dashboard.table.evi"} />
                                </a>
                            </th>
                        }

                        {(exportarDados || indice.toLowerCase() === "evi") &&
                            <th><a href='#' style={{ color: campoOrdem === "excesso_evi_arbov" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "excesso_evi_arbov") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("excesso_evi_arbov")
                            }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.excesso"} /> <Translator path={"dashboard.table.evi"} />  </a>
                            </th>
                        }
                        
                        {(exportarDados || indice.toLowerCase() === "ears") &&   
                            <th><a href='#' style={{ color: campoOrdem === "sinal_ears_consec_arbov" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "sinal_ears_consec_arbov") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("sinal_ears_consec_arbov")
                            }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_ears"} />  </a>
                            </th>
                        }

                           
                        {(exportarDados || indice.toLowerCase() === "ears") && 
                            <th>
                                <a href='#' style={{ color: campoOrdem === "limite_superior_ears_arbov" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "limite_superior_ears_arbov") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("limite_superior_ears_arbov")
                                    }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.limite"} />  <Translator path={"dashboard.table.ears"} />  
                                </a>
                            </th>     
                        } 

                        {(exportarDados || indice.toLowerCase() === "ears") &&
                            <th><a href='#' style={{ color: campoOrdem === "excesso_ears_arbov" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "excesso_ears_arbov") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("excesso_ears_arbov")
                            }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.excesso"} />  <Translator path={"dashboard.table.ears"} />  </a>
                            </th>
                        } 
                        {(exportarDados || indice.toLowerCase() === "ens") &&      
                            <th>
                                <a href='#' style={{ color: campoOrdem === "sinal_ens_consec_arbov" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "sinal_ens_consec_arbov") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("sinal_ens_consec_arbov")
                                    }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_ens"} />  
                                </a>
                            </th>  
                        }

                        {(exportarDados || indice.toLowerCase() === "ens") && 
                            <th>
                                <a href='#' style={{ color: campoOrdem === "limite_superior_ens_arbov" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "limite_superior_ens_arbov") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("limite_superior_ens_arbov")
                                    }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.limite_ens"} />  
                                </a>
                            </th>     
                        } 

                        {(exportarDados || indice.toLowerCase() === "ens") &&
                            <th><a href='#' style={{ color: campoOrdem === "excesso_ens_arbov" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "excesso_ens_arbov") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("excesso_ens_arbov")
                            }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.excesso_ens"} />  </a>
                            </th>
                        }   

                        {(exportarDados || indice.toLowerCase() === "glm") &&      
                            <th>
                                <a href='#' style={{ color: campoOrdem === "sinal_ambos_glm_arbov" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "sinal_ambos_glm_arbov") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("sinal_ambos_glm_arbov")
                                    }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_glm"} />  
                                </a>
                            </th>  
                        }

                        {(exportarDados || indice.toLowerCase() === "glm") && 
                            <th>
                                <a href='#' style={{ color: campoOrdem === "limite_superior_glm_arbov" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "limite_superior_glm_arbov") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("limite_superior_glm_arbov")
                                    }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.limite_glm"} />  
                                </a>
                            </th>     
                        } 

                        {(exportarDados || indice.toLowerCase() === "glm") &&
                            <th><a href='#' style={{ color: campoOrdem === "excesso_glm_arbov" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "excesso_glm_arbov") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("excesso_glm_arbov")
                            }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.excesso_glm"} />  </a>
                            </th>
                        }  
                         

                    </tr>
                </thead>
                <tbody>
                    {tabelaOrdenada().map((value, index, array) => {
                        const isMesmoEstado = index < array.length - 1 && value["cod_uf"] === array[index + 1]["cod_uf"];
                      
                        return (
                            <tr key={index}>
                                <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeEstadoTabela(value["estado"]) }}>{ /*isMesmoEstado && index > 0 ? "" : */value["uf"]}</a></td>
                                <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeMunicipioTabela(value["cod_ibge"]) }}>{value["municipio"]}</a></td>
                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["total_arbov"]))}</td>
                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["total_geral"]))}</td>  
                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{fixaCasasDecimais(trataNaNeNull(value["taxaarbov_atendtotais"]), 2)}</td>
     
                                
                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["dqi"] === "Apto" ? t("dashboard.apto") : t("dashboard.nao_apto")}</td>
                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{fixaCasasDecimais(trataNaNeNull(value["perc_completude"]),2)}</td>
                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{fixaCasasDecimais(trataNaNeNull(value["diff_2w"]),2)}</td>
                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["prob_gro_trend_arbov"] === null ? valorNil :fixaCasasDecimais(trataNaNeNull(value["prob_gro_trend_arbov"] * 100),2)}</td>

                                {(exportarDados || indice.toLowerCase() === "evi") &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["sinal_evi_consec_arbov"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["sinal_evi_consec_arbov"]))}</td>
                                }
                                
                                {(exportarDados || indice.toLowerCase() === "evi") &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNullInt(value["limite_superior_evi_arbov"]))}</td>
                                }

                                {(exportarDados || indice.toLowerCase() === "evi") &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["excesso_evi_arbov"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["excesso_evi_arbov"]))}</td>
                                }
                                
                                {(exportarDados || indice.toLowerCase() === "ears") &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["sinal_ears_consec_arbov"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["sinal_ears_consec_arbov"]))}</td>
                                }

                                {(exportarDados || indice.toLowerCase() === "ears") &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNullInt(value["limite_superior_ears_arbov"]))}</td>
                                }

                                {(exportarDados || indice.toLowerCase() === "ears") &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["excesso_ears_arbov"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["excesso_ears_arbov"]))}</td>
                                }
                                

                                {(exportarDados || indice.toLowerCase() === "ens") &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["sinal_ens_consec_arbov"] === null || value["sinal_ens_consec_arbov"] === undefined ? valorNil : (value["sinal_ens_consec_arbov"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["sinal_ens_consec_arbov"]))}</td>
                                }
                                {(exportarDados || indice.toLowerCase() === "ens") &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["limite_superior_ens_arbov"] === null ? valorNil : (value["limite_superior_ens_arbov"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["limite_superior_ens_arbov"]))}</td>
                                }
                                {(exportarDados || indice.toLowerCase() === "ens") &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["excesso_ens_arbov"] === null ? valorNil : (value["excesso_ens_arbov"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["excesso_ens_arbov"]))}</td>
                                }

                                {(exportarDados || indice.toLowerCase() === "glm") &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["sinal_glm_consec_arbov"] === null || value["sinal_glm_consec_arbov"] === undefined ? valorNil : (value["sinal_glm_consec_arbov"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["sinal_glm_consec_arbov"]))}</td>
                                }
                                {(exportarDados || indice.toLowerCase() === "glm") &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["limite_superior_glm_arbov"] === null ? valorNil : (value["limite_superior_glm_arbov"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["limite_superior_glm_arbov"]))}</td>
                                }
                                {(exportarDados || indice.toLowerCase() === "glm") &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["excesso_glm_arbov"] === null ? valorNil : (value["excesso_glm_arbov"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["excesso_glm_arbov"]))}</td>
                                }

                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            
        </div>
    );
}

export default Tabela;