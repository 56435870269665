export default {
    translations: { // Mesmo valor usado nas configurações (defaultNS)
      links_menu:{
        mapa: 'Full Dashboard',
        metagenomic: 'Metagenomic',
        ibp: 'IBP',
        climate: 'Climate',
        webbased: 'Web Based',
        mapa_resumido: 'Summary Dashboard',
      },
      sankey: {
        sem_dados: 'No data available',
        col1tab1: 'Hub City by Path Proportion',
        col2tab1: 'State of Hub City',
        col3tab1: 'Hub City IBGE Code',
        col4tab1: 'Proportion of Paths Leading to The Hub',
        col1tab2: 'Gateway City',
        col2tab2: 'IBGE Code of Gateway City',
        col3tab2: 'City to be Affected',
        col4tab2: 'Federative Unity to be Affected',
        col5tab2: 'IBGE Code of City to be Affected',
        col6tab2: 'Proportion of Paths Leading to the Affetced Cities',
        col1tab3: 'Suggested Cities for Pathogen Early Detection From the Selected City',
        col2tab3: 'State of Hub City',
        col3tab3: 'Hub City IBGE Code',
        col4tab3: 'Proportion of Paths Leading to the Hub',
      },
      dashboard: {
        //title: 'Monitoring Upper Respiratory Infection in Primary Healthcare (PHC) in Brazil',
        //subtitle: 'Overview of Upper Respiratory Infections (URI) encounters',
        a: 'to',
        title: 'Tracking of Arbovirus Encounters in Primary Health Care (PHC) in Brazil',
        subtitle: '',
        queda: 'Decreasing',
        estavel: '&nbsp;&nbsp;Stable&nbsp;&nbsp;',
        alerta: 'Atention',
        alta : 'Increasing',
        data : 'Date',
        buscar_uf: 'Filter UF',
        buscar_municipio: 'Filter Municipality',
        cidade : 'Municipality',
        estado : 'State',
        populacao: 'Population',
        atencao: 'Attention',
        aviso: 'Warning',
        semana_ep: 'Epidemiological Week',
        tosse:'Cough',
        febre:'Fever',
        dqi: 'DQI',
        ears: 'EARS',
        ens: 'AESOP Signal',
        evi_simples: 'EVI',
        otc: 'OTC',
        ambos: 'Both',
        sem_aviso: 'No Warning',
        aviso_consecutivo: 'Consecutive Warning',
        apto: 'Fit',
        nao_apto: 'Not Fit',
        no_data: 'No Data',
        probabilidade_crescimento: 'Growth Probability ',
        sem_informacao: 'No information',
        titulo_mapa_taxa_crescimento: 'Growth Rate of Individuals Infected with Arbovirus',
        titulo_mapa_atendimentos_excedentes : 'Exceeding Individuals Infected with Arbovirus',
        titulo_mapa_evi: 'Warning Sign',
        evi: 'EVI (Epidemic Volatility Index)',
        legenda_mapa_taxa_crescimento: 'Growth rate (%)',
        legenda_mapa_atendimentos_excedentes: 'Exceeding Encounters',
        perc_completude: 'Completeness (%)',
        perc_tempestividade: 'Timing (%)',
        retangulos:{
          titulo_excesso : 'Exceeding Individuals Infected with Arbovirus',
          mil: 'K',
          milhoes: 'M',
          bilhoes: 'B',
        },
        dados_climaticos: 'Climate Data',
        graficos: {
          titulo_mm: 'Individuals Infected with Arbovirus Proportion',
          legenda_mm: {
            1:'Moving Average',
            2:'Proportion (Individuals Infected with Arbovirus/Total Encounters)',
          },
          titulo_variacao_mm : 'Growth Rate of Individuals Infected with Arbovirus Proportion (Moving Average)',
          titulo_atendimento_gripais : 'Individuals Infected with Arbovirus in PHC',
          titulo_atendimento_totais : 'Total Encounters in PHC',
          titulo_excesso : 'Exceeding Individuals Infected with Arbovirus from Expected Limit',
          titulo_limite : 'Individuals Infected with Arbovirus Expected Limit',
          titulo_precipitacao : 'Precipitation',
          titulo_humidade : 'Humidity',
          titulo_indice_calor :'Heat Index',
          titulo_temp2m :'Temperature at 2 meters',
          titulo_med_movel_precipitacao : 'Moving Average Precipitation',
          titulo_med_movel_humidade : 'Moving Average Humidity',
          titulo_med_movel_indice_calor :'Moving Average Heat Index',
          titulo_med_movel_temp2m :'Moving Average Temperature at 2 meters',
          titulo_tosse_febre: 'Cough And Fever',
        },
        table: {
          titulo: 'Enquiry of Indicators in the Municipalities',
          titulo_estados: 'Enquiry of Indicators in the Municipalities',
          col1: 'Municipality',
          col2: 'Population',
          col3: 'Growth Rate (Individuals Infected with Arbovirus ratio)',
          col9: 'EVI (Epidemic Volatility Index)',
          col4: 'Exceeding Individuals Infected with Arbovirus',
          col5: 'Expected Limit of Encounters (Individuals Infected with Arbovirus)',
          col6: 'Individuals Infected with Arbovirus Proportion',
          col7: 'Individuals Infected with Arbovirus',
          col8: 'Total Encounters',
          estado: 'UF',
          limite: 'Limit',
          limite_glm: 'Limit GLM',
          excesso: 'Exceeding',
          excesso_glm: 'Exceeding GLM',
          evi: 'EVI',
          ears: 'Ears',
          sinal_evi: 'Consecutive EVI Signal',
          sinal_ears: 'Consecutive Ears Signal',
          sinal_glm: 'Consecutive GLM Signal',
          sinal_ens: 'Consecutive AESOP Signal',
          excesso_ens: 'Excesso AESOP Signal ',
          limite_ens: 'Limite AESOP Signal',
          sinais: 'Total Signals',
        },
        todos: 'All',
        mensagemNaoFoiPossivelCalcularTaxaCrescimento: 'Unable to calculate growth rate',
        subMensagemNaoFoiPossivelCalcularTaxaCrescimento: 'Undef',
        mesAno: 'Month/Year: ',
        flowMapNomeLayerMob: 'Mobility Routes',
        semanal: 'Weekly',
        mensal: 'Monthly',
        excesso: 'Exceeding Individuals Infected with Arbovirus from Expected Limit',
        excesso_simples: 'Exceeding',
        gripe: 'Flu',
        arbo: 'Arbo',
      }
    }
  }